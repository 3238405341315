<template>
  <div class="grid">
		<div class="col-12">
            <div class="card">
                <Toast/>
                <Message ref="message" severity="error" :closable="true" v-if="errors.length">{{errors}}</Message>

                <Fieldset legend="Filter" :toggleable="true" :collapsed="true" class="mb-3">
                    <div class="p-fluid">
                        <div class="formgrid grid">
                            <div class="field col-12 md:col-6 lg:col-3">
                                <label for="period">Periode</label>
                                <Calendar selectionMode="single" v-tooltip.top="'Pilih Bulan'" :manualInput="false" v-model="period" view="month" dateFormat="MM yy" icon="pi pi-calendar" :showIcon="true"/>
                            </div>
                        </div>
                    </div>
                    <Button :loading="loading" label="Filter" icon="pi pi-search" class="p-button-warning mr-2 my-1" @click="getDataTable" />
                </Fieldset>

                <DataTable :value="dataTable" responsiveLayout="scroll" :loading="loading" dataKey="se_id" @sort="onSort($event)" :resizableColumns="true">
                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <div>
                                <Button :loading="loadingExcel" label="Export Excel" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('xlsx')" />
                                <Button :loading="loadingCsv" label="Export CSV" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('csv')" />
                            </div>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="search" placeholder="Cari..." @keyup.enter="getDataTable" />
                            </span>
                        </div>
                    </template>
                    <Column field="number" header="No." :style="{width:'20px'}" >
                        <template #body="slotProps">
                        {{ slotProps.data.number }}
                        </template>
                    </Column>
                    <Column field="kode_mr" header="Username">
                        <template #body="slotProps">
                            {{slotProps.data.kode_mr}}
                        </template>
                    </Column>
                    <Column field="name_mr" header="Nama">
                        <template #body="slotProps">
                            {{slotProps.data.name_mr}}
                        </template>
                    </Column>
                    <Column field="periode" header="Periode">
                        <template #body="slotProps">
                            {{slotProps.data.periode}}
                        </template>
                    </Column>
                    <Column field="total_database" header="Total Database">
                        <template #body="slotProps">
                            {{slotProps.data.total_database}}
                        </template>
                    </Column>
                    <Column field="total_database_visit" header="Total Database Visit">
                        <template #body="slotProps">
                            {{slotProps.data.total_database_visit}}
                        </template>
                    </Column>
                    <Column field="call_coverage" header="% database visit (Call Coverage)">
                        <template #body="slotProps">
                            {{slotProps.data.call_coverage}}
                        </template>
                    </Column>
                    <Column field="total_callplan_extra" header="Total Call Plan Extra">
                        <template #body="slotProps">
                            {{slotProps.data.total_callplan_extra}}
                        </template>
                    </Column>
                    <Column field="total_visit_extra" header="Total Realisasi Visit Extra">
                        <template #body="slotProps">
                            {{slotProps.data.total_visit_extra}}
                        </template>
                    </Column>
                    <Column field="total_callplan_all" header="Total Call Plan">
                        <template #body="slotProps">
                            {{slotProps.data.total_callplan_all}}
                        </template>
                    </Column>
                    <Column field="total_visit_all" header="Total Realisasi Visit">
                        <template #body="slotProps">
                            {{slotProps.data.total_visit_all}}
                        </template>
                    </Column>
                    <Column field="callrate_all_visit" header="% ACH Visit (Call Rate)">
                        <template #body="slotProps">
                            {{slotProps.data.callrate_all_visit}}
                        </template>
                    </Column>
                    <template #empty>
                        No data found.
                    </template>
                    <template #loading>
                        Loading data. Please wait.
                    </template>
                    <template #footer>
                        In total there are {{totalItemsCount ? totalItemsCount : 0 }} data.
                    </template>
                </DataTable>

                <Paginator v-model:rows="rows" v-model:first="offset" :totalRecords="totalItemsCount" :rowsPerPageOptions="[10,20,30]">
                    <template>
                        {{totalItemsCount}}
                    </template>
                </Paginator>
            </div>
      </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from "vuex";
import { numberingDatatable } from '../../utils/helpers';

export default {
    data() {
        return {
            // loading
            loading: false,
            loadingExcel: false,
            loadingCsv: false,
            
            // filter
            period: new Date(),
            filters: {
            },

            //datatables
            data: null,
            dataTable: null,
            rows: 10,
            search: null,
            offset: null,
            field: null,
            sort: null,
            page: null,
            totalItemsCount: 0,
        }
    },
    mounted() {
        this.getDataTable();
        this.$store.commit('setErrors', {});
        this.$store.commit('setErrorAdd', {});
        this.$store.commit('setErrorEdit', {});
    },
    watch: {
        offset() {
            this.getDataTable();
        },
        rows() {
            this.getDataTable();
        },
    },
    computed:{
        ...mapGetters(['errors', 'errorEdit', 'errorAdd']),
        period_month_label(){ 
            return moment(this.period, 'YYYY-MM').format('YYYY-MM'); 
        },
    },
    methods: {
        formatDate(value){
            if (value) {
                return moment.utc(value).format('DD-MM-YYYY')
            }
        },
        // DATATABLE
        getDataTable(){

            this.loading=true;
            this.page = (this.offset / this.rows) + 1;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/report-actual-visit',
                params: {
                    "search" : this.search,
                    "per_page" : this.rows,
                    "page" : this.page,
                    "order_by" : this.field,
                    "sort_by" : this.sort,
                    "periode" : this.period_month_label,
                }
            })
            .then(res => {
                this.data = res.data.data.data;
                this.dataTable = numberingDatatable(res.data.data.data, this.page, this.rows);
                this.totalItemsCount = res.data.data.total;
                this.rows = parseInt(res.data.data.per_page);
                this.loading=false;
            })
            .catch((err) => {
                console.log(err);
            });
        },
        onSort(event) {
            this.field = event.sortField;
            this.sort = event.sortOrder == '1' ? 'ASC' : 'DESC';
            this.getDataTable();
        },
        // EXPORT
        exportExcelCSV(ext){
            if(ext == 'xlsx'){
                this.loadingExcel=true;
            }else if(ext == 'csv'){
                this.loadingCsv=true;
            }

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/report-actual-visit/export',
                responseType: 'blob',
                params: {
                    "ext" : ext,
                    "periode" : this.period_month_label,
                }
            })
            .then(response => {
                let fileUrl = window.URL.createObjectURL(response.data);
                let fileLink = document.createElement('a');

                fileLink.href = fileUrl;

                fileLink.setAttribute('download', 'Report Actual Visit Export.' + ext);
                
                document.body.appendChild(fileLink)

                fileLink.click();
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }

            })
            .catch((err) => {
                console.log(err);
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }
            });
        },
    }
}
</script>